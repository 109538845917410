.mainlayout_page{
  height: 100%;
  .trigger{
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .trigger:hover{
    color: #1890ff;
  }
  .ant-layout.ant-layout-has-sider{
    height: 100%;
  }
  .ant-layout-header{
    background-color: #fff;
    padding: 0 20px;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
  }
  .ant-layout-sider-light{
    box-shadow: 2px 0 8px 0 rgba(29,35,41,.05);
  }
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right: none;
  }
  .ant-layout-content{
    flex: 1;
    overflow-y: overlay;
  }
  .sider_container{
    .company_logo_c{
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      box-sizing: border-box;
      .company_logo_icon{
        width: 216px;
        height: 50px;
      }
    }
    .sider_menu_c{
      margin-top: 0px;
    }
  }
  .header_container{
    .header_top{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      .refresh_icon{
        font-size: 18px;
        cursor: pointer;
      }
      .ant-menu-horizontal{
        border-bottom: none;
      }
      .ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
        margin-left: 10px;
      }
    }
    .header_bottom{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0px 20px;
      .header_back_icon{
        font-size: 18px;
        margin-right: 15px;
        cursor: pointer;
      }
      .header_breadcrumb{
        border-left: 1px solid #eeeeee;
        padding: 9px 0;
        padding-left: 15px;
      }
    }
  }
  .ant-layout-header{
    height: auto;
    line-height: unset;
    padding: 0px;
  }
  .layout_content{
    padding: 20px;
    box-sizing: border-box;
  }
}
.modify_pwd_modal{
  margin-top: 15px;
  .modal_btn{
    .modal_btn_confirm,.modal_btn_cancel{
      width: 80px;
    }
    .modal_btn_cancel{
      margin-left: 20px;
    }
  }
}
@primary-color: rgba(51, 171, 160, 1);